<template>
  <div>
    <Pane />

    <div class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="用户名" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.userName" placeholder="姓名" />
            </a-form-model-item>
            <a-form-model-item>
              <a-radio-group v-model="form.status">
                <a-radio :value="1">正常</a-radio>
                <a-radio :value="0">停用</a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item>
              <a-button-group>
                <a-tooltip title="查询">
                  <a-button @click="query" type="primary">查询</a-button>
                </a-tooltip>
                <a-tooltip title="重置">
                  <a-button @click="reset">重置</a-button>
                </a-tooltip>
              </a-button-group>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="2" v-if="$getPermission($route.path + '/add')">
          <div class="right" style="margin-top: 4px">
            <a-button @click="$router.push($route.path + '/add')" type="primary"
              >新增</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column key="code" title="头像">
          <template slot-scope="text">
            <div>
              <a-avatar v-if="text.header" :size="32" :src="text.header" />
              <default-avatar v-else :name="text.userName" />
            </div>
          </template>
        </a-table-column>
        <a-table-column
          key="name"
          title="用户名"
          data-index="name"
        ></a-table-column>
        <a-table-column
          key="userName"
          title="姓名"
          data-index="userName"
        ></a-table-column>
        <a-table-column
          key="mobile"
          title="手机号"
          data-index="mobile"
        ></a-table-column>
        <a-table-column title="用户部门">
          <template slot-scope="text">
            {{ text.employeeVo ? text.employeeVo.deptUniqueName : "--" }}
          </template>
        </a-table-column>

        <a-table-column
          key="email"
          title="邮箱"
          data-index="email"
        ></a-table-column>

        <a-table-column key="role" title="角色">
          <template slot-scope="text">
            <div>
              {{
                Array.isArray(text.roles)
                  ? text.roles.map((item) => item.name).join(" ")
                  : ""
              }}
            </div>
          </template>
        </a-table-column>
        <a-table-column title="备注" data-index="remarks"></a-table-column>
        <a-table-column
          title="创建者"
          data-index="createUserName"
        ></a-table-column>
        <a-table-column title="创建时间" data-index="createAt"></a-table-column>

        <a-table-column title="状态" data-index="status" align="center">
          <template slot-scope="status">
            <a-badge v-if="status === 1" status="success" text="正常" />
            <a-badge v-if="status === 0" status="error" text="停用" />
          </template>
        </a-table-column>

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <private-button path="edit" :params="{ uuid: text.uuid }">
              <span>编辑</span>
            </private-button>

            <private-button
              path="delete"
              routeType="method"
              danger
              @clickButton="deleteText(text)"
            >
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/setting/user";
import { mapMutations } from "vuex";
import DefaultAvatar from "@/components/default-avatar";
export default {
  components: {
    DefaultAvatar,
  },

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    ...mapMutations("setting", ["setEditingUser"]),

    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个用户吗？",
        onOk() {
          remove({
            uuid: text.uuid,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #fff;
  padding: 8px;
}
</style>